import { Anchor, Col, Row } from 'antd';
import styles from './index.module.less';
import Logo from '@/logo.svg';
import { AnchorLinkItemProps } from 'antd/es/anchor/Anchor';

export function NavBar() {
  const items: AnchorLinkItemProps[] = [
    {
      key: 'home',
      href: '#hero',
      title: '关于',
    },
    {
      key: 'features',
      href: '#features',
      title: '功能',
    },
    {
      key: 'customers',
      href: '#customers',
      title: '客户',
    },
  ];
  return (
    <Row
      className={styles['navbar']}
      align="middle"
      justify={'space-between'}
      gutter={12}
    >
      <Col>
        <Logo />
      </Col>
      <Col>
        <Anchor
          className={styles['menus']}
          direction={'horizontal'}
          affix={false}
          items={items}
        ></Anchor>
      </Col>
    </Row>
  );
}

import Logo from '@/logo.svg';
import { ENV_CONFIG } from '../../../env-config';
import styles from './index.module.less';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { Button, Card, Image, Col, Row, Typography, Avatar } from 'antd';
import Meta from 'antd/es/card/Meta';

import avatar1 from './a1.png';
import avatar2 from './a2.png';
import avatar3 from './a3.png';

export function Comments() {
  return (
    <div className={styles['comments']} id={'customers'}>
      <h1>客户体验</h1>
      <Row gutter={[24, 24]} style={{ width: '100%', maxWidth: 800 }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Card hoverable style={{ width: '100%' }}>
            <Typography.Paragraph>
              超卖通的特别之处在于它的业务契合度极其高，内置的自动化提升了3-5倍的工作效率，无需编排，比影刀更容易落地实施。
            </Typography.Paragraph>
            <Meta
              title="波总"
              description="#广州某电商公司 #老板"
              avatar={<Avatar size={'large'} src={avatar1} />}
            />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Card hoverable style={{ width: '100%' }}>
            <Typography.Paragraph>
              我司由多个子公司组成，超卖通聚合托管了所有子公司的账号，大大地提高了我处理订单的速度，同时降低了出错率，让我更有信心。
            </Typography.Paragraph>
            <Meta
              title="Tracy"
              description="#广州某科技公司 #财务"
              avatar={<Avatar size={'large'} src={avatar2} />}
            />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Card hoverable style={{ width: '100%' }}>
            <Typography.Paragraph>
              上架商品是一个很无趣的事情，自从用了超卖通自动采集、自动上架、违规信息检查等等功能后，我可以放更多时间在新产品的开发上。
            </Typography.Paragraph>
            <Meta
              title="陈工"
              description="#广州某信息科技公司 #员工"
              avatar={<Avatar size={'large'} src={avatar3} />}
            />
          </Card>
        </Col>
      </Row>
      <Player
        autoplay
        loop
        src="https://assets5.lottiefiles.com/private_files/lf30_smtruyrg.json"
        style={{ height: '300px', width: '500', marginBottom: 36 }}
      ></Player>
    </div>
  );
}

import Logo from '@/logo.svg';
import { ENV_CONFIG } from '../../../env-config';
import styles from './index.module.less';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { Button, Card, Col, Row } from 'antd';
import Meta from 'antd/es/card/Meta';
import { ContactButton } from '../ContactButton';

export function Features() {
  const span = {};
  return (
    <div className={styles['features']} id={'features'}>
      <h1>超卖通有什么</h1>
      <Row gutter={[24, 24]} style={{ width: '100%', maxWidth: 800 }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Card hoverable style={{ width: '100%' }}>
            <Meta
              title="网页自动化"
              description="支持定制网页自动化，如数据提取、数据抓取、表单填写、文件上传等网页操作，轻松实现网页自动化。"
            />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Card hoverable style={{ width: '100%' }}>
            <Meta
              title="高度可控的任务执行系统"
              description="易用透明的任务系统以及清晰的事件反馈，包括并发、人工模拟等待等控制，让您可以完全掌控所有计划任务。"
            />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Card hoverable style={{ width: '100%' }}>
            <Meta
              title="更细致的权限体系"
              description="通过超卖通的权限细分，您可以实现比电商平台更加细腻的权限细分，充分保护您的账号数据安全。"
            />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Card hoverable style={{ width: '100%' }}>
            <Meta
              title="多账号统一管理"
              description="支持托管不同的账号，免除了频繁切换账号登录的麻烦，并且通过聚合多家公司数据，订单等数据一目了然。"
            />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Card hoverable style={{ width: '100%' }}>
            <Meta
              title="预置常用自动化脚本"
              description="预置自动化脚本，如不同账号间的商品迁移、商品抓取、自动上下架等，并且支持快速扩展，效率提升快人一步！"
            />
          </Card>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Card hoverable style={{ width: '100%' }}>
            <Meta
              title="量化分析"
              description="通过操作日志等数据，以报表、排名等形式量化员工工作成果，提高员工主动性和积极性。"
            />
          </Card>
        </Col>
      </Row>
      <Player
        autoplay
        loop
        src="https://assets1.lottiefiles.com/packages/lf20_j9e7ic.json"
        style={{ height: '200px', width: '100%', marginBottom: 36 }}
      ></Player>
      <ContactButton></ContactButton>
    </div>
  );
}

import Logo from '@/logo.svg';
import { Divider, Space, Typography } from 'antd';
import styles from './index.module.less';

export function Footer() {
  const span = {};
  return (
    <div className={styles['footer']}>
      <Space direction="vertical" align="center">
        <Logo />
        <Typography.Text type="secondary">
          <small>&copy;2023</small>
        </Typography.Text>
      </Space>
    </div>
  );
}

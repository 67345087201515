import { Button, Popover, Image } from 'antd';
import { ButtonType } from 'antd/lib/button';
import qrcode from './qrcode.jpg';
export function ContactButton(props: { type?: ButtonType }) {
  return (
    <Button type={props.type || 'primary'} size="large">
      了解更多
    </Button>
  );
}
